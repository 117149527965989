import Vue from 'vue'
import App from './App.vue'
import VueRouter from 'vue-router'
import VueMeta from 'vue-meta';

import { BootstrapVue } from 'bootstrap-vue'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
Vue.use(BootstrapVue)
Vue.use(VueRouter)
Vue.use(VueMeta)

import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import {
  faFlickr, faInstagram, faGithubSquare, faYoutubeSquare, faFacebookSquare, faLinkedin, faSpotify
} from '@fortawesome/free-brands-svg-icons'
import {
  faSpinner, faEnvelope, faEnvelopeSquare, faBook
} from '@fortawesome/free-solid-svg-icons'
import Landing from "@/routes/Landing";
import Pidging from "@/routes/Pidging";
import NotFound from "@/routes/NotFound";
import Picturevert from "@/routes/Picturevert";
import Fiction from "@/routes/Fiction.vue";

library.add(
    faFlickr, faInstagram, faGithubSquare, faYoutubeSquare, faFacebookSquare, faLinkedin, faSpinner, faEnvelope, faEnvelopeSquare, faSpotify, faBook
)
Vue.component('font-awesome-icon', FontAwesomeIcon)

Vue.config.productionTip = false

const routes = [
  {
    path: '/',
    component: Landing
  },
  {
    path: '/pidging',
    component: Pidging
  },
  {
    path: '/picturevert',
    component: Picturevert
  },
  {
    path: '/fiction',
    component: Fiction
  },
  {
    path: '/tech',
    component: Landing
  },
  {
    path: '/*',
    component: NotFound
  }
];

const router = new VueRouter({
  mode: 'history',
  routes
})

new Vue({
  router,
  render: h => h(App),
}).$mount('#app')

<template>
  <section id="app-content" :class="[bgClass]">
    <article id="info-chapter">
      <header>
        <h1>Borys Turchyk Webspace</h1>
        <span class="description">
          <p>A collection of references to my personal projects and other online presence resources, in no particular order.</p>
          <p>Oh, and my name is sometimes spelled Boris Turchik - it's still me! 😄</p>
      </span>
      </header>
      <section id="project-links" class="links">
        <h2>Projects and professional resources</h2>
        <p>
          <a href="https://hydralien.net/blog/">Personal Blog</a>
          <span class="description">
            Various notes about projects I'm working on - but not necessarily just that. Basically whatever I think is worth sharing from professional experience and achievements.
          </span>
        </p>
        <p><a href="https://hydralien.net/picturevert">Picturevert</a>
          <span class="description">
            A pocket image color inversion app - another experiment in Flutter and Dart, and also to satisfy personal need in making inverted pictures.
          </span>
        </p>
        <p>
          <a href="https://github.com/hydralien?tab=repositories">
            <font-awesome-icon :icon="['fab', 'github-square']"/>
            Github Repositories
          </a>
          <span class="description">
            All my repos that are open to public - some of them are referenced here specifically, but there's certainly more junk there!
          </span>
        </p>
        <p>
          <a href="https://meetingless.io/">
            Meetingless.io
          </a>
          <span class="description">
            A "manifesto" resource to promote a more productive attitude towards meetings, a collaboration with the idea author <a
              href="http://elenaborisova.com/">Elena Borisova</a>
          </span>
        </p>
        <p><a href="https://hydralien.net/anothertab/">AnotherTab</a>
          <span class="description">
            A Google Chrome, Mozilla Firefox and Microsoft Edge extension to simplify bookmarks and extensions access via New Tab page.
          </span>
        </p>
        <p><a href="https://hydralien.net/pidging">Pidging</a>
          <span class="description">
            A pocket travel vocabulary app for iOS and Android that helps travellers to communicate in local language during the journey - an experiment in Flutter, Dart and Python.
          </span>
        </p>
        <p><a href="https://traject.online/"><span class="badge badge-dark">WIP</span> Traject</a>
          <span class="description">
            An adventure planner and a safety notifier, an idea lazyli being developed in a free time. Technologically React.js/Typescript as FE abd Node.js/Typescript on BE.
          </span>
        </p>
        <p>
          <a href="https://www.linkedin.com/company/designchallengeio/about/">
            DesignChallenge.io
          </a>
          <span class="description">
            A past project (now disabled), once co-founded together with
            <a href="http://elenaborisova.com/">Elena Borisova</a> and <a
              href="https://www.linkedin.com/in/flavia-dos-anjos-90830266/">Flavia dos Anjos</a>,
            was an effort to to hire creative people with relevant skills and levelling the bar for everyone with similar skills but different occupation. Currently unsupported and remains as a purely portfolio project.
          </span>
        </p>
        <p><a href="https://github.com/hydralien/FitBit-Funktional-Watchface">FitBit Funktional watchface</a>
          <span class="description">
            It's available in FitBit watchface store through search by "Funktional" - this is a reference to its source code.
          </span>
        </p>
        <p><a href="https://www.linkedin.com/in/borysturchyk">
          <font-awesome-icon :icon="['fab', 'linkedin']"/>
          LinkedIn profile
        </a>
          <span class="description">
            A modern CV replacement
          </span>
        </p>
      </section>

      <section id="media-links" class="links">
        <h2>Personal and media resources</h2>
        <p>
          <a href="https://hydralien.net/fiction">
            <font-awesome-icon :icon="['fa', 'book']"/>
            Short Fiction
          </a>
          <span class="description">
            A collection of short (very) fictional stories I create in my spare time.
          </span>
        </p>
        <p>
          <a href="https://www.flickr.com/photos/hydralien/albums">
            <font-awesome-icon :icon="['fab', 'flickr']"/>
            Annual photo albums
          </a>
          <span class="description">
            I usually share my photos regularly on other platforms (such as Instagram, facebook and Google Photos), but
            once a year I gather what I consider the best shots of year past, and aggregate them on Flickr. Here's the list of links
            to those annual albums:
          </span>
        </p>
        <p class="album-list">
          <span class="sub-item"><a
              href="https://www.flickr.com/photos/hydralien/albums/72177720322955867">2024</a></span>
          <span class="sub-item"><a
              href="https://www.flickr.com/photos/hydralien/albums/72177720313574876">2023</a></span>
          <span class="sub-item"><a
              href="https://www.flickr.com/photos/hydralien/albums/72177720304710610">2022</a></span>
          <span class="sub-item"><a
              href="https://www.flickr.com/photos/hydralien/albums/72177720295531394">2021</a></span>
          <span class="sub-item"><a
              href="https://www.flickr.com/photos/hydralien/albums/72157717578011212">2020</a></span>
          <span class="sub-item"><a
              href="https://www.flickr.com/photos/hydralien/albums/72157712414861603">2019</a></span>
          <span class="sub-item"><a
              href="https://www.flickr.com/photos/hydralien/albums/72157699245832500">2018</a></span>
          <span class="sub-item"><a
              href="https://www.flickr.com/photos/hydralien/albums/72157662229766317">2017</a></span>
          <span class="sub-item"><a
              href="https://www.flickr.com/photos/hydralien/albums/72157674552749083">2016</a></span>
          <span class="sub-item"><a
              href="https://www.flickr.com/photos/hydralien/albums/72177720295536483">2015</a></span>
          <span class="sub-item"><a
              href="https://www.flickr.com/photos/hydralien/albums/72157649919881386">2014</a></span>
          <span class="sub-item"><a
              href="https://www.flickr.com/photos/hydralien/albums/72157638992745253">2013</a></span>
        </p>

        <p>
          <a href="https://www.youtube.com/c/BorisTurchik/videos">
            <font-awesome-icon :icon="['fab', 'youtube-square']"/>
            Experiments in noise
          </a>
          <span class="description">
            Now and then I feel the urge to produce some noise using my vocal cords - previously it safely rested on Soundcloud,
            but at some point I decided that Youtube is easier and more approachable medium.
          </span>
        </p>
        <p>
          <a href="https://www.facebook.com/hydralien/">
            <font-awesome-icon :icon="['fab', 'facebook-square']"/>
            personal Facebook page
          </a>
          <span class="description">
            An official social network presence.
          </span>
        </p>
        <p>
          <a href="https://www.instagram.com/hydralien/">
            <font-awesome-icon :icon="['fab', 'instagram']"/>
            personal Instagram page.
          </a>
          <span class="description">
            Where I post most of my everyday photo materials, and now and then some other weirdness I encounter here and there.
          </span>
        </p>
        <p>
          <a href="https://higherwhiter.com">
            <font-awesome-icon :icon="['fab', 'instagram']"/>
            HigherWhiter Project
          </a>
          <span class="description">
          An alpine climbing photo project that I'm using to promote higher elevations, but also any travelling-related photos
          I take during hikes and climbs.
        </span>
        </p>
        <p>
          <a href="https://open.spotify.com/user/11133422294">
            <font-awesome-icon :icon="['fab', 'spotify']" />
            Spotify playlists
          </a>
          <span class="description">
            Odd and regular music collections - a bit of everything in the form of playlists.
          </span>
        </p>
        <p>
          <a href="https://hydralien.github.io/drunk-mondrian/">
            <font-awesome-icon :icon="['fab', 'github-square']"/>
            Perpetually Drunk Mondrian
          </a>
          <span class="description">
            An art project of continuous random line drawing painted in several vivid basic colors, Piet Mondrian style!
            The code is available at <a href="https://github.com/hydralien/drunk-mondrian">https://github.com/hydralien/drunk-mondrian</a>
          </span>
        </p>
      </section>
    </article>
  </section>
</template>

<script>

export default {
  name: "LandingPage",
  components: {},
  data: function () {
    return {
      bgImageNo: 0
    }
  },
  mounted() {
    this.$data.bgImageNo = Math.floor(Math.random() * 3)
  },
  computed: {
    bgClass() {
      return `image-class-${this.$data.bgImageNo}`
    }
  }
}

</script>

<style scoped>
#app-content {
  font-size: 1.3rem;
  font-family: 'Raleway', sans-serif;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  text-align: left;
  color: #2c3e50;
  padding: 2rem;
  width: 100vw;
  min-height: 100vh;
}

.image-class-0::before {
  background-image: url("../assets/h.png");
}

.image-class-0::before, .image-class-1::before, .image-class-2::before {
  content: "";
  background-repeat: no-repeat;
  background-position: 80% center;
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  z-index: -1;
  height: 100vh;
  width: 100%;
}

.image-class-1::before {
  background-image: url("../assets/h1.png");
}

.image-class-2::before {
  background-image: url("../assets/h2.png");
}

p {
  margin: 0 0 0.7em 1em !important;
  line-height: 1.3;
}

ul {
  list-style-type: none;
}

h1, h2, h3 {
  font-family: 'Monda', sans-serif;
}

header {
  background-color: rgba(255, 255, 255, 0.9);
  border-radius: 10px;
}

#info-chapter {
  width: 60%;
}

.links {
  background-color: rgba(240, 240, 255, 0.9);
  border-radius: 10px;
  padding: 1rem;
  margin: 2rem 0;
}

.description, .sub-item {
  padding-left: 1em;
  margin-bottom: 1em;
}

.description {
  font-size: 0.8em;
}

#media-links {
  background-color: rgba(240, 255, 240, 0.9);
}

.album-list {
  display: flex;
  flex-wrap: wrap;
}

@media only screen and (max-width: 700px) {
  #app-content {
    background-position: center;
  }

  .image-class-0::before, .image-class-1::before, .image-class-2::before {
    background-position: center;
  }

  #info-chapter {
    width: 100%;
  }
}
</style>

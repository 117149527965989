<template>
  <section class="page-container">
    <header class="header d-flex justify-content-start">
      <div class="header-logo">
        <img v-bind:src="currentBookImage" alt="Literature in abundance" class="header-image">
      </div>
      <div class="header-text">
        <h2>Short Fiction</h2>
        <h6>Stories for no reason</h6>
      </div>
    </header>
    <section class="d-flex justify-content-between col-12 main-content">
      <section class="description-content">
        <h4>The Last Dance</h4>
        <ul>
          <li><a href="https://www.amazon.com/dp/B0DWYVKXJ4">Kindle Edition on Amazon</a></li>
        </ul>
        <blockquote style="font-style: italic">
          "I can’t see her face, my dancing partner - the hood of her cape covers her face, I only see the shape of a sharp chin. She’s tall, taller than me - and I’ve always been considered among the tallest guys in our town - and yet her cape’s hood conceals her face in such a way I can not see it. I know she’s beautiful, I have absolute certainty I could describe her beauty for hours on end, and yet I haven’t seen her face - just know it without doubt or hesitation. It feels like I don’t have to see her face right now either, not yet."
        </blockquote>

        <h4>Limboism</h4>
        <ul>
          <li><a href="https://www.amazon.com/dp/B0DWVGYBXY">Kindle Edition on Amazon</a></li>
        </ul>
        <blockquote>
          It’s a story about solitude, attachment, strange encounters, secret experiments, unexplained phenomena, survival - and, mostly, capybaras. It's a fictional tale that is set in an unidentified rural area and follows the unexpected adventure of Audrey and her two capybaras.
        </blockquote>

        <h4>Better Than Nothing</h4>
        <ul>
          <li><a href="https://www.amazon.com/dp/B0DWLFBKMX">Kindle Edition on Amazon</a></li>
        </ul>
        <blockquote>
          Why does the entertainment industry exist even after the world that was consuming it has ended? What had caused its demise? Who am I in this world and what purpose do I still have? This is the story about technology, complacency, end of the world, and hope.
        </blockquote>
      </section>
    </section>
    <footer class="d-flex justify-content-between footer">
      <div class="copyright">
        © <a href="https://hydralien.net">Borys Turchyk</a>, 2025
      </div>
      <div class="contacts">
        <span>Contact:</span>
<!--        <a href="https://facebook.com/pidgingapp/">-->
<!--          <font-awesome-icon :icon="['fab', 'facebook-square']"/>-->
<!--        </a>-->
        <a href="mailto:hydra.public@gmail.com">
          <font-awesome-icon icon="envelope-square"/>
        </a>
      </div>
    </footer>
  </section>
</template>

<script>

const bookImages = [
    "https://res.cloudinary.com/dmhd8lsl2/image/upload/v1739390107/hydralien.net/books_2_g6jnla.jpg",
  "https://res.cloudinary.com/dmhd8lsl2/image/upload/v1739390107/hydralien.net/books_1_xcxlys.jpg"
];

const currentBookImage = bookImages[Math.floor(bookImages.length * Math.random())];

export default {
  name: "FictionPage",
  metaInfo: {
    title: `Short Fiction - Stories For No Reason`,
    meta: [
      {
        vmid: 'description',
        name: 'description',
        content: "Collection of short fictional stories that I'm writing in my free time."
      },
    ],
    link: [
      { rel: 'favicon', href: '/fiction/favicon.ico' }
    ]
  },
  data: () => {
    return {
      currentBookImage
    }
  }
}
</script>

<style scoped>

blockquote {
  padding-left: 2em;
}

.app-logo {
  width: 70px;
  height: 70px;
  margin: 0 1rem;
  border-radius: 1em;
}

.main-content {
  width: 100%;
  flex: 1 0 auto;
}

.description-content {
  display: inline-block;
  padding: 1rem 0 1rem 1rem;
}

.description-content h2 {
  margin-bottom: 1rem;
}

.description-content h4 {
  margin-top: 1.5rem;
}

.slider {
  max-width: 600px;
  display: inline-block;
  margin: 0 2rem;
  padding: 0 2rem;
}

.slider img {
  width: 250px;
  border: 1px solid lightgrey;
  border-radius: 10px;
}

.header {
  padding: 3em 2em 0 2em;
  display: flex;
  flex-direction: column;
}

.header-logo {
  width: 100%;
  justify-content: center;
  display: flex;
}

.header-image {
  width: 85%;
  margin-bottom: 2em;
  border-radius: 1em;

}

.header h2 {
  color: rgb(38, 57, 119);
  margin: 0;
}

.header h6 {
  color: rgb(38, 99, 154);
}

.custom-arrow::before {
  color: darkslategray;
}

.header-text {
  padding: 5px 0;
}

.store-icon {
  width: 60px;
}

.footer {
  padding: 1rem 2rem;
  margin-top: 2rem;
  /*position: fixed;*/
  bottom: 0;
  width: 100vw;
  flex-shrink: 0;
}

.page-container {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

.contacts a {
  font-size: 2rem;
  margin: 0 0.3rem;
  vertical-align:middle
}

.copyright {
  padding: 0.5em 0;
}

p {
  text-indent: 1em;
}

.block-disabled {
  filter: grayscale(1);
}

@media (max-width: 576px) {
  .slider {
    width: 300px;
    display: block;
    margin: 0 1rem;
    padding: 0 1rem;
  }
  .description-content {
    display: block;
  }
  .main-content {
    flex-wrap: wrap;
  }
  .footer {
    padding: 1rem 1rem;
  }
}
</style>
